import { send } from "../index";

export function saveFilmTag(data, opts = {}) {
    // 添加影片标签
    return send({
        url: "/admin/movieInfo/filmTag/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getFilmTagDetail(data, opts = {}) {
    // 影片标签详情
    return send({
        url: "/admin/movieInfo/filmTag/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateFilmTag(data, opts = {}) {
    // 修改影片标签
    return send({
        url: "/admin/movieInfo/filmTag/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteFilmTag(data, opts = {}) {
    // 删除影片标签
    return send({
        url: "/admin/movieInfo/filmTag/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getFilmTagList(data, opts = {}) {
    // 影片标签列表
    return send({
        url: "/admin/movieInfo/filmTag/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getAllFilmTagList(data, opts = {}) {
    // 获取全部影片标签
    return send({
        url: "/admin/movieInfo/filmTag/getAll.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveFilmActor(data, opts = {}) {
    // 添加影片人员
    return send({
        url: "/admin/movieInfo/filmActor/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getFilmActorDetail(data, opts = {}) {
    // 影片人员详情
    return send({
        url: "/admin/movieInfo/filmActor/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateFilmActor(data, opts = {}) {
    // 修改影片人员
    return send({
        url: "/admin/movieInfo/filmActor/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteFilmActor(data, opts = {}) {
    // 删除影片人员
    return send({
        url: "/admin/movieInfo/filmActor/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getFilmActorList(data, opts = {}) {
    // 影片人员列表
    return send({
        url: "/admin/movieInfo/filmActor/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getFilmInfoList(data, opts = {}) {
    // 影片信息列表
    return send({
        url: "/admin/movieInfo/filmInfo/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveFilmInfo(data, opts = {}) {
    // 添加影片信息
    return send({
        url: "/admin/movieInfo/filmInfo/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getFilmInfoDetail(data, opts = {}) {
    // 影片信息详情
    return send({
        url: "/admin/movieInfo/filmInfo/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateFilmInfo(data, opts = {}) {
    // 修改影片信息
    return send({
        url: "/admin/movieInfo/filmInfo/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveFilmClassify(data, opts = {}) {
    // 添加影片分类
    return send({
        url: "/admin/movieInfo/filmClassify/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getFilmClassifyDetail(data, opts = {}) {
    // 影片分类详情
    return send({
        url: "/admin/movieInfo/filmClassify/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateFilmClassify(data, opts = {}) {
    // 修改影片分类
    return send({
        url: "/admin/movieInfo/filmClassify/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteFilmClassify(data, opts = {}) {
    // 删除影片分类
    return send({
        url: "/admin/movieInfo/filmClassify/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getFilmClassifyList(data, opts = {}) {
    // 影片分类列表
    return send({
        url: "/admin/movieInfo/filmClassify/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getAllFilmClassifyList(data, opts = {}) {
    // 获取全部影片分类
    return send({
        url: "/admin/movieInfo/filmClassify/getAll.do",
        method: "POST",
        data,
        ...opts,
    });
}